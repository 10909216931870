import React from "react";
import ProfDisplay from "./ProfDisplay";

export default (): JSX.Element => {
   return (
      <>
         <ProfDisplay />
      </>
   );
};
